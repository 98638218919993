import React, { useState, ReactElement } from 'react';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { zodResolver } from '@hookform/resolvers/zod';
import { ru } from 'date-fns/locale';
import { endOfDay, format, isSameDay, isValid, parse, startOfDay } from 'date-fns';

import { Box } from '@mui/system';
import { Dialog, IconButton, TextField, Typography } from '@mui/material';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { MainButton } from 'shared/buttons/MainButton/MainButton';

import { DatePickerDay, datePickerPopperStyled } from 'ui/datePicker/datePicker';
import { modalTitleStyle, modalSubtitleStyle } from 'ui/modals/modalGlobal';
import { dialogDetachPatientModalStyled } from 'ui/modals/modalDetach/modalDetach';
import { inputFormStyle } from 'ui/inputs/inputsForm';

import { useDetachPatients } from 'hooks/patients/useDetachPatients';

import { DetachmentPatientPassModalProps } from 'interfaces/patients/patientInterfaces';
import { DetachPatientsHookResponse } from 'interfaces/hooks/patientsHooksInterfaces';

import { DataPeriod, ErrorData } from 'interfaces/global/globalInterfaces';

import { useGetPeriodStaffAction } from 'hooks/periods/useGetPeriodStaffAction';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { disabledDates } from 'utils/dateHelpers';
import { validationDateDetach, DateDetachParams } from './validationDetach';

export const DetachPatientModal: React.FC<DetachmentPatientPassModalProps> = ({
  open,
  massDetach,
  patientData,
  selectedStaff,
  isEditDetachModal,
  detachDate,
  handleClose,
  onSubmit,
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [dateDetachPatient, setDateDetachPatient] = useState<Date | null>(null);

  const rendersPickerDayDetachmentStaff = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ): ReactElement => {
    const valueToday = dateDetachPatient !== null ? isSameDay(date, dateDetachPatient) : false;

    return (
      <DatePickerDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        isToday={valueToday}
      />
    );
  };

  const mainFilterPeriodStaff: DataPeriod | null = sessionStorageGetItem('mainFilterPeriodStaff', null);

  const { periodStaffAction } = useGetPeriodStaffAction({
    period: mainFilterPeriodStaff,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const disabledDays = (date: Date): boolean => {
    const actualDayTimeStamp = startOfDay(date).getTime();

    const canDetachSinceTimestamp = periodStaffAction
      ? startOfDay(parse(periodStaffAction.canDetachSince, 'yyyy-MM-dd', new Date())).getTime()
      : null;
    const canDetachSinceTimestampMax = periodStaffAction
      ? endOfDay(parse(periodStaffAction.canAttachOrDetachMaxDate, 'yyyy-MM-dd', new Date())).getTime()
      : null;
    if (disabledDates.some((item) => item === date.getTime())) {
      return disabledDates.some((item) => item === date.getTime());
    }

    if (canDetachSinceTimestamp && canDetachSinceTimestampMax) {
      return actualDayTimeStamp < canDetachSinceTimestamp || actualDayTimeStamp > canDetachSinceTimestampMax;
    }
    if (canDetachSinceTimestamp) {
      return actualDayTimeStamp < canDetachSinceTimestamp;
    }
    if (canDetachSinceTimestampMax) {
      return actualDayTimeStamp > canDetachSinceTimestampMax;
    }
    return false;
  };

  const {
    setValue,
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<DateDetachParams>({
    mode: 'onTouched',
    resolver: zodResolver(validationDateDetach),
  });

  const resetForm = (): void => {
    setDateDetachPatient(null);
    setValue('detach_date', '', { shouldDirty: true });
  };

  const { handleDetachPatients, isLoadDetachPatients } = useDetachPatients({
    massDetach,
    patient: patientData || null,
    selectedPatients: selectedStaff && !!selectedStaff?.length ? selectedStaff : null,

    onSuccess: async (response: DetachPatientsHookResponse): Promise<void> => {
      if (response.res.ok) {
        enqueueSnackbar(response.message, { variant: 'success' });
        queryClient.invalidateQueries('dataCompanyPatients');
        resetForm();
        handleClose();
      } else {
        await response.res.json().then(
          (data: {
            message: string;
            errors: {
              message: string;
              code: string;
              field: string;
            }[];
          }) => {
            enqueueSnackbar(data.errors[0].message, { variant: 'error' });

            data.errors.forEach((item) => {
              setError('detach_date', {
                type: 'custom',
                message: item.message,
              });
            });
          }
        );
      }
    },
    onError: (response: DetachPatientsHookResponse) => {
      response.res.json().then(
        (data: {
          message: string;
          errors: {
            message: string;
            code: string;
            field: string;
          }[];
        }) => {
          enqueueSnackbar(data.errors[0].message, { variant: 'error' });

          data.errors.forEach((item) => {
            setError('detach_date', {
              type: 'custom',
              message: item.message,
            });
          });
        }
      );
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm();
        handleClose();
      }}
      sx={dialogDetachPatientModalStyled}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h3" sx={modalTitleStyle}>
            Открепление пациентов
          </Typography>
          {isEditDetachModal && detachDate ? (
            <Typography variant="subtitle1" sx={modalSubtitleStyle}>
              Обратите внимание, что пациент уже подан на открепление c {format(new Date(detachDate), 'dd.MM.yyyy')},
              повторное действие скорректирует дату откреплениям без отображения в документах
            </Typography>
          ) : (
            <>
              {' '}
              <Typography variant="subtitle1" sx={modalSubtitleStyle}>
                Статус для выбранных пациентов изменится на “Завершенный”, оказание услуг будет остановлено.
              </Typography>
              <Typography variant="subtitle1" sx={modalSubtitleStyle}>
                Для завершения открепления выберите дату, с которой оно вступит в силу.
              </Typography>
              {periodStaffAction?.relativesInsurance?.autoRelativeDetach?.value === 'enabled' && (
                <Typography variant="subtitle1" sx={modalSubtitleStyle}>
                  При откреплении застрахованного сотрудника, его родственники будут откреплены.
                </Typography>
              )}
            </>
          )}
        </Box>
        <IconButton
          aria-label="close modal"
          sx={{ width: '40px', height: '40px' }}
          onClick={() => {
            resetForm();
            handleClose();
          }}
        >
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(handleDetachPatients)}
      >
        <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Укажите дату открепления"
            value={dateDetachPatient}
            renderDay={rendersPickerDayDetachmentStaff}
            shouldDisableDate={(date) => disabledDays(date)}
            PopperProps={{
              sx: datePickerPopperStyled,
            }}
            onChange={(newValue: Date | null) => {
              if (newValue && isValid(newValue)) {
                register('detach_date', {
                  value: format(newValue, 'yyyy-MM-dd'),
                });

                setValue('detach_date', format(newValue, 'yyyy-MM-dd'), { shouldDirty: true });
                setDateDetachPatient(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                label="Укажите дату открепления"
                variant="standard"
                error={!!errors.detach_date?.message}
                helperText={errors.detach_date?.message}
                sx={() => inputFormStyle(false)}
                required
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton sx={{ width: '28px', height: '28px' }} onClick={resetForm}>
                        <CloseOutlinedIcon fontSize="small" />
                      </IconButton>

                      {params.InputProps?.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>

        <Box sx={{ marginTop: '28px', alignSelf: 'flex-end' }}>
          <MainButton
            textButton="Открепить"
            typeBtn="submit"
            mainBtn
            disabled={!dateDetachPatient || isLoadDetachPatients}
            handleClick={onSubmit}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
