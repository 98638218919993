import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Box } from '@mui/system';
import { Dialog, Typography, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { modalBlockFooterStyle, modalTypographyColor } from 'ui/modals/modalGlobal';
import { useViewedNotifications } from 'hooks/notifications/useViewedNotifications';
import { InfoNotificationModalProps } from 'interfaces/notifications/notificationsInterfaces';
import { grayDarkColor, themeColor } from 'utils/uiConstants';
import { useGetTaskActionStaff } from 'hooks/other/useGetTaskActionStaff';
import { getNotificationModalActionStaffText } from 'utils/tablesMethods';
import { modalAddFromFileContent, modalDetachContent, statusesHR } from 'utils/constants';
import { ActionStaffModal } from '../ActionStaffModal/ActionStaffModal';
import { InfoNotificationModalText } from './InfoNotificationModalText';

export const InfoNotificationModal: React.FC<InfoNotificationModalProps> = ({ open, notification, handleClose }) => {
  const queryClient = useQueryClient();
  const { handleViewedNotifications } = useViewedNotifications({
    onSuccess: () => queryClient.invalidateQueries('dataUnviewedNotifications'),
  });

  const [isActionStaffModal, setActionStaffModal] = useState<boolean>(false);
  const [isTaskAvailable, setTaskAvailable] = useState<boolean>(false);
  const taskId = notification?.meta.task_id || '';

  const { dataTaskActionStaff, refetchTaskActionStaff } = useGetTaskActionStaff({
    taskId,
    onSuccess: () => setTaskAvailable(true),
  });

  const refetch = (): void => {
    refetchTaskActionStaff();
  };

  const isOperationSuccess =
    ((dataTaskActionStaff?.data?.total_successful_creating && dataTaskActionStaff.data.total_successful_creating > 0) ||
      (dataTaskActionStaff?.data?.total_successful_detaching &&
        dataTaskActionStaff?.data?.total_successful_detaching > 0)) &&
    dataTaskActionStaff?.data?.errors_records?.length === 0;

  useEffect((): any => {
    if (open && dataTaskActionStaff?.data.status !== 'success') {
      const interval = setInterval(() => {
        refetch();
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dataTaskActionStaff]);

  return (
    <>
      <Dialog
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '800px',
            overflow: 'auto',
            padding: '20px 24px 24px',
          },
        }}
        onClick={(evt) => {
          evt.stopPropagation();
          handleViewedNotifications(notification?.uuid);
          handleClose();
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Typography variant="h3" sx={() => modalTypographyColor('#3e414d')}>
                {notification?.text || ''}
              </Typography>
            </Box>
          </Box>
          <IconButton
            aria-label="close modal"
            sx={{ width: '40px', height: '40px' }}
            onClick={(evt) => {
              evt.stopPropagation();
              handleViewedNotifications(notification?.uuid);
              handleClose();
            }}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box>
          {notification?.meta.notification_type === 'status_change' &&
            (notification?.meta?.full_text ? (
              <InfoNotificationModalText linkText={notification?.meta?.full_text} />
            ) : (
              <>
                <Box
                  component="p"
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: '0.02em',
                  }}
                >
                  {notification?.meta.supplementary_agreement_info && 'Изменен статус '}
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {notification?.meta.supplementary_agreement_info || 'Данные не заполнены'}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      color: notification?.meta.hr_status
                        ? statusesHR[notification?.meta.hr_status].color
                        : grayDarkColor,
                    }}
                  >
                    {notification?.meta.hr_status
                      ? statusesHR[notification?.meta.hr_status].title
                      : 'Данные не заполнены'}
                  </Box>
                </Box>
                <Link to="/documents" style={{ maxWidth: 'max-content', display: 'block', textDecoration: 'none' }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ ...modalTypographyColor(themeColor), textDecoration: 'underline' }}
                  >
                    Перейти в дополнительные соглашения и платежи
                  </Typography>
                </Link>
              </>
            ))}
          {notification?.meta.notification_type === 'slot_action' && (
            <Box
              component="p"
              sx={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.02em',
              }}
            >
              {getNotificationModalActionStaffText(
                notification?.meta.slot_action_type,
                dataTaskActionStaff?.data.total_count_records,
                notification?.meta.slot_action_type === 'attach_from_file'
                  ? dataTaskActionStaff?.data.total_successful_creating
                  : dataTaskActionStaff?.data.total_successful_detaching
              )}
            </Box>
          )}
          {notification?.meta.notification_type === 'payments' && (
            <Box
              component="p"
              sx={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.02em',
                whiteSpace: 'pre-wrapd',
              }}
              dangerouslySetInnerHTML={{ __html: notification?.meta?.full_text || 'Данные не заполнены' }}
            />
          )}
        </Box>

        <Box sx={modalBlockFooterStyle}>
          {notification?.status.value === 'unviewed' && (
            <MainButton
              textButton="Оставить непрочитанным"
              handleClick={(evt) => {
                evt.stopPropagation();
                handleClose();
              }}
            />
          )}
          {dataTaskActionStaff?.data.status === 'success' &&
            notification?.meta.notification_type === 'slot_action' &&
            isTaskAvailable && (
              <MainButton
                textButton={isOperationSuccess ? 'Посмотреть детали' : 'Продолжить операцию'}
                mainBtn
                handleClick={(evt) => {
                  evt.stopPropagation();
                  setActionStaffModal(true);
                }}
              />
            )}
          <MainButton
            textButton="Закрыть"
            mainBtn
            handleClick={(evt) => {
              evt.stopPropagation();

              handleViewedNotifications(notification?.uuid);
              handleClose();
            }}
          />
        </Box>
      </Dialog>
      <ActionStaffModal
        celeryType={notification?.meta.slot_action_type === 'attach_from_file' ? 'slots' : 'employees'}
        open={isActionStaffModal}
        dataTaskActionStaff={dataTaskActionStaff?.data}
        handleClose={() => {
          setActionStaffModal(false);
          handleClose();
        }}
        displayErrors={false}
        textContent={
          notification?.meta.slot_action_type === 'attach_from_file' ? modalAddFromFileContent : modalDetachContent
        }
      />
    </>
  );
};
