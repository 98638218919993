import React, { useMemo } from 'react';
import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { useGetTemplateForDetachToExcel } from 'hooks/other/useGetTemplateForDetach';
import { mainBtnStyle } from 'ui/buttons/buttonsMain';
import { ActionWithPatientModalProps } from 'interfaces/patients/patientInterfaces';
import { useGetPeriodStaffAction } from 'hooks/periods/useGetPeriodStaffAction';
import {
  buttonsBlock,
  modalContainer,
  textContent,
} from 'ui/modals/actionWithPatientFromFileModal/ActionWithPatientFromFileModalStyles';
import { ErrorData } from 'interfaces/global/globalInterfaces';
import { useGetTemplateForAddToExcel } from 'hooks/other/useGetTemplateForAdd';
import { useSnackbar } from 'notistack';
import { themeColor } from 'utils/uiConstants';
import { TooltipActionsInfo } from 'ui/tooltips/tooltipAction';
import { disabledDatesFilesAction, getFormattedDate } from 'utils/dateHelpers';
import { startOfDay } from 'date-fns';

export const ActionWithPatientFromFileModal: React.FC<ActionWithPatientModalProps> = ({
  type,
  open,
  isActionMuted,
  periodInMainFilter,
  handleClose,
  handleChangeDetachFromFile,
  handleChangeAttachFromFile,
  companies,
  status,
}) => {
  const isFileActionDisabled = useMemo(() => {
    const currentDay = startOfDay(new Date());
    return disabledDatesFilesAction?.some((item) => item === currentDay.getTime());
  }, []);

  const tooltipText = useMemo(() => {
    if (isActionMuted && type === 'detach') {
      return 'Нет доступных дат для открепления из файла';
    }
    if (isActionMuted && type === 'attach') {
      return 'Нет доступных дат для прикрепления из файла';
    }
    if (isFileActionDisabled && type === 'detach') {
      return 'Функция открепления из файла будет доступна с 09.01.2023. Приносим извинения за предоставленные неудобства ';
    }
    if (isFileActionDisabled && type === 'attach') {
      return 'Функция прикрепления из файла будет доступна с 09.01.2023. Приносим извинения за предоставленные неудобства ';
    }
    return '';
  }, [isActionMuted, isFileActionDisabled, type]);
  const { enqueueSnackbar } = useSnackbar();

  const { handleTemplateForDetachToExcel } = useGetTemplateForDetachToExcel({
    onSuccess: () => {
      enqueueSnackbar('Скачивание файла успешно завершено', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Произошла ошибка при скачивании', { variant: 'error' });
    },
  });

  const { handleTemplateForAddToExcel } = useGetTemplateForAddToExcel({
    periodInMainFilter,
    companies,
    onSuccess: () => {
      enqueueSnackbar('Скачивание файла успешно завершено', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Произошла ошибка при скачивании', { variant: 'error' });
    },
  });

  const { periodStaffAction } = useGetPeriodStaffAction({
    period: periodInMainFilter,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} sx={{ ...modalContainer, fullWidth: 'true' }}>
      <Box sx={modalHeaderStyle}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={modalTitleStyle}>
            {type === 'detach' ? 'Открепление пациентов' : 'Прикрепление пациентов'}
          </Typography>
        </Box>
        <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box component="p" sx={{ ...textContent, marginBottom: '18px' }}>
        Для загрузки данных используйте специальный шаблон, который можно скачать по кнопке ниже
      </Box>
      {type === 'detach' && (
        <Box component="p" sx={textContent}>
          По условиям вашего договора списки на открепление необходимо подавать за{' '}
          <Box
            component="span"
            sx={{
              color: themeColor,
            }}
          >
            {periodStaffAction ? periodStaffAction.canDetachSinceDays : 'нет данных'} р.дн.
          </Box>{' '}
          до плановой даты вступления изменений в силу.
          <Box
            sx={{
              marginTop: '18px',
            }}
          >
            При заполнении списка указывайте дату не ранее{' '}
            <Box
              component="span"
              sx={{
                color: themeColor,
              }}
            >
              {periodStaffAction ? getFormattedDate(periodStaffAction?.canDetachSince, 'dd.MM.yyyy') : 'нет данных'}
            </Box>
            , чтобы операция прошла успешно.
          </Box>
          {periodStaffAction?.relativesInsurance?.autoRelativeDetach?.value === 'enabled' && (
            <Box
              sx={{
                marginTop: '18px',
              }}
            >
              При откреплении застрахованного сотрудника, его родственники будут откреплены.
            </Box>
          )}
        </Box>
      )}
      {type === 'attach' && (
        <Box component="p" sx={textContent}>
          По условиям вашего договора списки на прикрепление необходимо подавать за{' '}
          <Box
            component="span"
            sx={{
              color: themeColor,
            }}
          >
            {periodStaffAction ? periodStaffAction.canAttachSinceDays : 'нет данных'} р.дн.
          </Box>{' '}
          до плановой даты вступления изменений в силу.
          <Box
            sx={{
              marginTop: '18px',
            }}
          >
            При заполнении списка указывайте дату не ранее{' '}
            <Box
              component="span"
              sx={{
                color: themeColor,
              }}
            >
              {periodStaffAction ? getFormattedDate(periodStaffAction?.canAttachSince, 'dd.MM.yyyy') : 'нет данных'}
            </Box>
            , чтобы операция прошла успешно.
          </Box>
        </Box>
      )}
      <Stack direction="row" justifyContent="end">
        {type === 'detach' && (
          <Box sx={buttonsBlock}>
            <MainButton textButton="Пример файла" handleClick={handleTemplateForDetachToExcel} />
            <TooltipActionsInfo placement="left" title={tooltipText}>
              <Box component="span">
                <Button
                  component="label"
                  sx={() => mainBtnStyle(true, false)}
                  disabled={isActionMuted || status === 'started' || status === 'pending' || isFileActionDisabled}
                >
                  Открепить из файла{' '}
                  {(status === 'started' || status === 'pending') && (
                    <CircularProgress
                      sx={{
                        marginLeft: '16px',
                      }}
                      size="16px"
                      color="inherit"
                    />
                  )}
                  <input
                    hidden
                    type="file"
                    accept=".xls, .xlsx"
                    onChange={(evt) => handleChangeDetachFromFile && handleChangeDetachFromFile(evt)}
                  />
                </Button>
              </Box>
            </TooltipActionsInfo>
          </Box>
        )}
        {type === 'attach' && (
          <Box sx={buttonsBlock}>
            <MainButton textButton="Пример файла" handleClick={handleTemplateForAddToExcel} />
            <TooltipActionsInfo placement="left" title={tooltipText}>
              <Box component="span">
                <Button
                  component="label"
                  sx={() => mainBtnStyle(true, false)}
                  disabled={isActionMuted || status === 'started' || status === 'pending' || isFileActionDisabled}
                >
                  Прикрепить из файла{' '}
                  {(status === 'started' || status === 'pending') && (
                    <CircularProgress
                      sx={{
                        marginLeft: '16px',
                      }}
                      size="16px"
                      color="inherit"
                    />
                  )}
                  <input
                    hidden
                    type="file"
                    accept=".xls, .xlsx"
                    onChange={(evt) => handleChangeAttachFromFile && handleChangeAttachFromFile(evt)}
                  />
                </Button>
              </Box>
            </TooltipActionsInfo>
          </Box>
        )}
      </Stack>
    </Dialog>
  );
};
