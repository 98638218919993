import React, { useState } from 'react';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Box } from '@mui/system';

import { AutocompleteInput } from 'shared/inputs/AutocompleteInput/AutocompleteInput';
import { TooltipTotalStats } from 'shared/tooltip/TooltipTotalStats/TooltipTotalStats';

import { useGetStatsCompanyByPatients } from 'hooks/companies/useStatsCompanyByPatients';
import { AutocompleteInputMultiple } from 'shared/inputs/AutocompleteInputMultiple/AutocompleteInputMultiple';
import { CustomBtn } from 'shared/buttons/MainFiltersButton/MainFiltersButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Company,
  DataPeriod,
  StaffFiltersProps,
  IStatsCompanyByPatients,
  DNA,
  ErrorData,
} from 'interfaces/global/globalInterfaces';
import { mainFiltersContainerStyle, mainFiltersSectionStyle, mainFiltersWrapperStyle } from 'ui/patients/mainFilters';
import { getDecimalLength } from 'utils/getDecimalLength';
import { StatsCompanyByPatients } from 'interfaces/hooks/companiesHooksInterfaces';
import { ContractInfoModal } from 'shared/modals/ContractInfoModal/ContractInfoModal';
import { useSnackbar } from 'notistack';

export const StaffFilters: React.FC<StaffFiltersProps> = ({
  dataPeriods,
  allCompanies,

  periodInMainFilter,
  handlePeriodInMainFilter,

  selectedCompanies,
  handleSelectedCompanies,
  periodStaffAction,
  managersHR,
  isSlotsAviable,
}) => {
  const [isTooltip, setTooltip] = useState<boolean>(false);
  const [contractInfoModal, setContractInfo] = useState<boolean>(false);
  const [statsCompanyByPatients, setStatsCompanyByPatients] = useState<IStatsCompanyByPatients>({
    less_30: 0,
    men_percentage: 0,
    more_30_and_less_45: 0,
    more_45_and_less_60: 0,
    more_60: 0,
    total_registered: 0,
    women_percentage: 0,
  });

  const handleTooltipClose = (): void => {
    setTooltip(false);
  };

  const handleTooltipOpen = (): void => {
    setTooltip(!isTooltip);
  };

  const { enqueueSnackbar } = useSnackbar();

  const { isLoadingStatsCompanyByPatients, isErrorStatsCompanyByPatients } = useGetStatsCompanyByPatients({
    company: selectedCompanies ? selectedCompanies[0] : null,
    period: periodInMainFilter,
    isSlotsAviable,

    onSuccess: ({ data }: StatsCompanyByPatients) => {
      const agesGroups: number[] = [data.less_30, data.more_30_and_less_45, data.more_45_and_less_60, data.more_60];
      const sumOfGroups: number = agesGroups.reduce((a, b) => a + b, 0);
      const commonGroup: number = Math.max(...agesGroups);
      const commonGroupIndex = agesGroups.indexOf(commonGroup);

      const decimal = getDecimalLength(sumOfGroups);

      agesGroups.splice(commonGroupIndex, 1);
      agesGroups.forEach((item: number, index: number) => {
        agesGroups[index] = parseFloat(((item / sumOfGroups) * 100).toFixed(sumOfGroups <= 99 ? 0 : decimal));
      });

      const commonGroupPercent = 100 - agesGroups.reduce((a, b) => a + b, 0);

      agesGroups.splice(commonGroupIndex, 0, commonGroupPercent);

      setStatsCompanyByPatients({
        men_percentage: data.men_percentage,
        less_30: agesGroups[0],
        more_30_and_less_45: agesGroups[1],
        more_45_and_less_60: agesGroups[2],
        more_60: agesGroups[3],
        total_registered: sumOfGroups,
        women_percentage: data.women_percentage,
      });
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const getPeriodValue = (): DataPeriod | null | string => {
    if (selectedCompanies?.length === 1 && periodInMainFilter) {
      return periodInMainFilter;
    }
    if (selectedCompanies?.length > 1 && dataPeriods.filter((item) => item.activePeriod).length > 0) {
      return '(Активный период)';
    }
    return '(Нет активного периода)';
  };

  return (
    <>
      <Box component="section" sx={mainFiltersSectionStyle}>
        <Box sx={mainFiltersWrapperStyle}>
          <Box sx={mainFiltersContainerStyle}>
            <AutocompleteInputMultiple
              label="Компания"
              placeholder={selectedCompanies?.length === 0 || selectedCompanies ? '' : 'Компания'}
              data={[{ title: 'Выбрать все компании' }, ...allCompanies]}
              value={selectedCompanies}
              allSelected={selectedCompanies.length === allCompanies.length}
              handleChange={(_event: React.SyntheticEvent, newValue: Company[]) => {
                const array = newValue;
                const counter = array.reduce(
                  // eslint-disable-next-line no-return-assign, no-sequences
                  (acc: DNA, current) => ((acc[current.title] = (acc[current.title] || 0) + 1), acc),
                  {}
                );
                const result = array.filter((obj: Company) => counter[obj.title] === 1);

                const selectedOptions = result.reduce((arr: Company[], obj: Company) => {
                  const exist: boolean = arr.some(({ title }) => obj.title === title);
                  if (!exist) {
                    arr.push(obj);
                  }
                  return arr;
                }, []);

                sessionStorage.setItem('mainFilterPeriodStaff', JSON.stringify(''));

                if (newValue.some((val) => val.title === 'Выбрать все компании')) {
                  if (selectedCompanies.length === allCompanies.length) {
                    handleSelectedCompanies([]);
                    sessionStorage.setItem('mainFilterCompaniesStaff', JSON.stringify([]));
                  } else {
                    handleSelectedCompanies(allCompanies.filter((option) => option.title !== 'Выбрать все компании'));
                    sessionStorage.setItem(
                      'mainFilterCompaniesStaff',
                      JSON.stringify(allCompanies.filter((option) => option.title !== 'Выбрать все компании') || [])
                    );
                  }
                } else {
                  handleSelectedCompanies(selectedOptions.filter((option) => option.title !== 'Выбрать все компании'));
                  sessionStorage.setItem(
                    'mainFilterCompaniesStaff',
                    JSON.stringify(selectedOptions.filter((option) => option.title !== 'Выбрать все компании') || [])
                  );
                }
              }}
              check
              disableCloseOnSelect
            />
            {periodInMainFilter?.activePeriod && isSlotsAviable && (
              <TooltipTotalStats
                open={isTooltip}
                isStats={statsCompanyByPatients}
                handleTooltipClose={handleTooltipClose}
              >
                <CustomBtn
                  aria-label="Общая статистика"
                  onClick={handleTooltipOpen}
                  sx={{
                    width: '40px',
                    height: '48px',
                  }}
                  disabled={
                    selectedCompanies?.length !== 1 ||
                    !periodInMainFilter ||
                    !!isErrorStatsCompanyByPatients ||
                    isLoadingStatsCompanyByPatients ||
                    statsCompanyByPatients.total_registered === 0
                  }
                >
                  <AssessmentOutlinedIcon sx={{ color: '#3e414d', fontSize: '14px' }} />
                </CustomBtn>
              </TooltipTotalStats>
            )}
          </Box>
          <Box sx={{ width: '100%' }}>
            <AutocompleteInput
              label="Период данных"
              placeholder="Период данных"
              data={selectedCompanies?.length === 1 ? dataPeriods || [] : []}
              value={getPeriodValue()}
              handleChangeObj={(_event: React.SyntheticEvent, newValue: DataPeriod) => {
                handlePeriodInMainFilter(newValue);
                sessionStorage.setItem('mainFilterPeriodStaff', JSON.stringify(newValue || ''));
              }}
            />
          </Box>
          {isSlotsAviable && (
            <CustomBtn
              disabled={selectedCompanies?.length !== 1 || !periodInMainFilter}
              sx={{
                width: '40px',
                height: '48px',
              }}
              aria-label="Информация"
              onClick={() => setContractInfo(true)}
            >
              <InfoOutlinedIcon fontSize="small" sx={{ color: '#BCBEC8', fontSize: '14px' }} />
            </CustomBtn>
          )}
        </Box>
      </Box>
      <ContractInfoModal
        open={contractInfoModal}
        handleClose={() => setContractInfo(false)}
        data={periodStaffAction}
        period={periodInMainFilter}
        managersHR={managersHR}
      />
    </>
  );
};
