import React, { useState, useEffect, useMemo } from 'react';

import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useGetOnePatient } from 'hooks/patients/useGetOnePatient';

import { PatientDataParams, TablePatientsHeadCell } from 'interfaces/patients/patientInterfaces';
import { tableCellCheckboxStyle, tableCellStyle, tableCellTypeStyle, tableRowStyle } from 'ui/tables/tables';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { modalHeaderStyle, modalTitleStyle } from 'ui/modals/modalGlobal';
import { parse } from 'date-fns';
import { useGetPeriodStaffAction } from 'hooks/periods/useGetPeriodStaffAction';
import { getFormattedDate } from 'utils/dateHelpers';
import { SearchRelativePatient } from 'interfaces/hooks/patientsHooksInterfaces';
import { MainButton } from 'shared/buttons/MainButton/MainButton';
import { CommonTextChoiceWrq, SlotsHrRelativeSlotInfoWrq } from '@bestdoctor/core-arch-api/models';
import { AccessList, DefaultPermissions, ErrorData } from 'interfaces/global/globalInterfaces';
import { ListCompanyRoleResourcesResponsesByAccept } from '@bestdoctor/core-arch-api/api/company-roles/types';
import { useSnackbar } from 'notistack';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { InfoStaffModal } from './InfoStaffModal/InfoStaffModal';

const headCells: readonly TablePatientsHeadCell[] = [
  {
    id: 'program__service_period__company__title',
    numeric: false,
    disablePadding: false,
    label: 'Юрлицо',
  },
  {
    id: 'patient__last_name',
    numeric: false,
    disablePadding: false,
    label: 'Имя (ID)',
  },
  {
    id: 'patient__birth_date',
    numeric: false,
    disablePadding: false,
    label: 'Дата р.',
  },
  {
    id: 'program__title',
    numeric: false,
    disablePadding: false,
    label: 'Программа',
  },
  {
    id: 'start_date',
    numeric: false,
    disablePadding: false,
    label: 'Период обслуживания',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Тип',
  },
];

const EnhancedTableHead: React.FunctionComponent = () => (
  <TableHead>
    <TableRow>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? 'right' : 'left'}
          padding={headCell.disablePadding ? 'none' : 'normal'}
          sx={tableCellStyle}
        >
          {headCell.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

interface RelativeSlotsModalProps {
  open: boolean;
  handleClose: () => void;
  data: SearchRelativePatient | null;
  accessList: AccessList[];
  rolesData: ListCompanyRoleResourcesResponsesByAccept['application/vnd.bestdoctor.v1-hr']['data'];
  defaultPermissions: DefaultPermissions;
  refetchSearchPatients: () => Promise<void>;
}

export const RelativeSlotsModal: React.FC<RelativeSlotsModalProps> = ({
  open,
  handleClose,
  data,
  accessList,
  rolesData,
  defaultPermissions,
  refetchSearchPatients,
}) => {
  // console.log(data?.patientType);
  // Для модалки предпросмотра
  const [patientDataTable, setPatientDataTable] = useState<string | null>(null);
  const [patientType, setPatientType] = useState<CommonTextChoiceWrq | null>(null);
  const [patientData, setPatientData] = useState<PatientDataParams | null>(null);
  const [isInfoStaffModal, setInfoStaffModal] = useState<boolean>(false);

  const patientDataWithPatientType = useMemo<PatientDataParams | null>(
    () => (patientData ? { ...patientData, patientType: patientType ?? { text: '', value: '' } } : null),
    [patientData, patientType]
  );

  const [isAddingForbiddenByDates, setAddingForbiddenByDates] = useState<boolean>(false);
  const [isDetachingForbiddenByDates, setDetachingForbiddenByDates] = useState<boolean>(false);

  const isSlotsAviable = isAccessAllowed(patientData?.companyUuid, 'HR_slots', accessList, defaultPermissions);

  const { enqueueSnackbar } = useSnackbar();

  const { isLoadingPatient, isErrorPatient, refetchPatient } = useGetOnePatient({
    patient: patientDataTable,
    onSuccess: (res) => {
      setPatientData(res);
      handleClose();
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const selectedCompanies =
    'mainFilterCompaniesStaff' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('mainFilterCompaniesStaff') || '')
      : '';

  const mainFilterPeriodStaff =
    'mainFilterPeriodStaff' in sessionStorage ? JSON.parse(sessionStorage.getItem('mainFilterPeriodStaff') || '') : '';

  const { periodStaffAction } = useGetPeriodStaffAction({
    period: mainFilterPeriodStaff,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  useEffect(() => {
    const parseCanAttachSince = periodStaffAction
      ? parse(periodStaffAction.canAttachSince, 'yyyy-MM-dd', new Date()).getTime()
      : null;

    const parseCanDetachSince = periodStaffAction
      ? parse(periodStaffAction.canDetachSince, 'yyyy-MM-dd', new Date()).getTime()
      : null;

    const parseCanActionMax = periodStaffAction
      ? parse(periodStaffAction.canAttachOrDetachMaxDate, 'yyyy-MM-dd', new Date()).getTime()
      : null;

    if (parseCanAttachSince && parseCanActionMax && parseCanDetachSince) {
      setAddingForbiddenByDates(parseCanActionMax - parseCanAttachSince < 0);
      setDetachingForbiddenByDates(parseCanActionMax - parseCanDetachSince < 0);
    }
  }, [mainFilterPeriodStaff, periodStaffAction]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '1312px',
          },
        }}
      >
        <Box sx={modalHeaderStyle}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h3" sx={modalTitleStyle}>
              Список слотов
            </Typography>
          </Box>
          <IconButton aria-label="close modal" sx={{ width: '40px', height: '40px' }} onClick={handleClose}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ width: '100%', marginTop: '12px', padding: '0 40px 0 40px' }}>
          <Paper sx={{ width: '100%', boxShadow: 'none', backgroundColor: 'transparent' }}>
            <TableContainer
              sx={{
                overflow: 'visible',
              }}
            >
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead />
                <TableBody
                  sx={{
                    overflowX: 'auto',
                  }}
                >
                  {data?.slots.map((patient: SlotsHrRelativeSlotInfoWrq) => (
                    <TableRow key={data?.id} sx={{ ...tableRowStyle, border: 'none' }}>
                      <TableCell component="th" scope="row" padding="none" sx={tableCellStyle}>
                        {patient?.company_title}
                      </TableCell>
                      <TableCell sx={tableCellCheckboxStyle}>
                        {data?.fullName}
                        <Box
                          sx={{
                            margin: '0',
                            padding: '0',
                            color: 'rgba(62, 65, 77, 0.6)',
                          }}
                        >
                          {`(ID: ${data?.id})`}{' '}
                          {patient?.patient_type.value === 'relative' && (
                            <Box
                              component="span"
                              sx={{
                                color: '#FF6D6D',
                                fontSize: '12px',
                              }}
                            >
                              Родственник
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{getFormattedDate(data?.birthDate, 'dd.MM.yyyy')}</TableCell>
                      <TableCell sx={tableCellStyle}>{patient?.program?.title}</TableCell>
                      <TableCell sx={tableCellStyle}>{`${getFormattedDate(
                        patient?.start_date,
                        'dd.MM.yyyy'
                      )} - ${getFormattedDate(patient?.end_date, 'dd.MM.yyyy')}`}</TableCell>
                      <TableCell sx={() => tableCellTypeStyle(patient.status.text)}>
                        {patient.status.text}
                        <IconButton
                          aria-label="add to shopping cart"
                          onClick={(evt) => {
                            evt.stopPropagation();

                            setPatientDataTable(patient?.uuid);
                            setPatientType(patient?.patient_type);

                            setInfoStaffModal(true);

                            setTimeout(() => {
                              refetchPatient();
                            }, 1);
                          }}
                        >
                          <InfoOutlinedIcon fontSize="small" sx={{ color: '#BCBEC8' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <Stack
          direction="row"
          justifyContent="end"
          sx={{
            margin: '6px 24px 24px 0',
          }}
        >
          <MainButton textButton="Закрыть" mainBtn handleClick={handleClose} />
        </Stack>
      </Dialog>
      {patientData && !isLoadingPatient && !isErrorPatient && (
        <InfoStaffModal
          isAddingForbiddenByDates={isAddingForbiddenByDates}
          isDetachingForbiddenByDates={isDetachingForbiddenByDates}
          open={isInfoStaffModal}
          selectedCompanies={selectedCompanies}
          patientData={patientDataWithPatientType}
          isLoadingPatient={isLoadingPatient}
          isErrorPatient={isErrorPatient}
          handleClose={() => setInfoStaffModal(false)}
          refetchPatient={refetchPatient}
          accessList={accessList}
          rolesData={rolesData}
          defaultPermissions={defaultPermissions}
          isSlotsAviable={isSlotsAviable}
          refetchSearchPatients={refetchSearchPatients}
        />
      )}
    </>
  );
};
