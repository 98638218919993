interface TrackerEvent {
  type: string;
  id?: string;
  goal?: string;
  userid?: string;
  version?: string;
  start?: number;
  value?: unknown;
  params?: unknown;
}

interface ITracker<Event extends TrackerEvent = TrackerEvent> {
  track(event: Event): void;
}

class Tracker {
  private userId: string | null = null;

  private tracker: ITracker;

  private enabled = false;

  constructor(tracker: ITracker, enabled?: boolean) {
    this.tracker = tracker;

    if (enabled !== undefined) {
      this.enabled = enabled;
    }
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  track(event: TrackerEvent & { withoutUserId?: boolean }) {
    if (!this.enabled) return;

    this.tracker.track(!event.withoutUserId && this.userId ? { ...event, userid: this.userId } : event);
  }
}

type TMREvent = Omit<TrackerEvent, 'withoutUserId'>;
export type TMR = Array<TMREvent>;

class MyTrackerAdapter implements ITracker {
  track(event: TrackerEvent): void {
    // eslint-disable-next-line no-underscore-dangle
    const tmr = window._tmr || (window._tmr = []);
    tmr.push(event);
  }
}

const tracker = new Tracker(new MyTrackerAdapter(), process.env.NODE_ENV === 'production');

const setUserId = (userId: string) => {
  tracker.setUserId(userId);
};

const trackSetUserId = () => {
  tracker.track({ type: 'setUserID' });
};

const trackExportPatient = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'export_patient' });
};

const trackAttachmentFormAttempt = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'attachment_form_attempt' });
};

const trackAttachmentFormSuccess = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'attachment_form_success' });
};

const trackAttachmentExcelAttempt = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'attachment_excel_attempt' });
};

const trackAttachmentExcelSuccess = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'attachment_excel_success' });
};

const trackRemovalPatientCardAttempt = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'removal_patient_card_attempt' });
};

const trackRemovalPatientCardSuccess = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'removal_patient_card_success' });
};

const trackRemovalExcelAttempt = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'removal_excel_attempt' });
};

const trackRemovalExcelSuccess = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'removal_excel_success' });
};

const trackRemovalMassAttempt = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'removal_mass_attempt' });
};

const trackRemovalMassSuccess = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'removal_mass_success' });
};

const trackPatientCardSendWelcome = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'patient_card_send_welcome' });
};

const trackPatientCardChangeProgram = () => {
  tracker.track({ id: '3514238', type: 'reachGoal', goal: 'patient_card_сhange_program' });
};

export const analyticsTracker = {
  setUserId,
  trackSetUserId,
  trackExportPatient,
  trackAttachmentFormAttempt,
  trackAttachmentFormSuccess,
  trackAttachmentExcelAttempt,
  trackAttachmentExcelSuccess,
  trackRemovalPatientCardAttempt,
  trackRemovalPatientCardSuccess,
  trackRemovalExcelAttempt,
  trackPatientCardSendWelcome,
  trackRemovalExcelSuccess,
  trackRemovalMassAttempt,
  trackRemovalMassSuccess,
  trackPatientCardChangeProgram,
};
