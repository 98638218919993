/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { AccordionHistory } from 'components/AccordionHistory/AccordionHistory';
import { ListStaff } from 'components/ListStaff/ListStaff';
import { StaffNavigation } from 'components/Navigation/StaffNavigation';

import { useGetCompanies } from 'hooks/companies/useGetCompanies';
import { useGetServicePeriods } from 'hooks/periods/useGetServicePeriods';
import { useSearchPatients } from 'hooks/patients/useSearchPatients';

import { PatientParams } from 'interfaces/patients/patientInterfaces';
import { Company, DataPeriod, ErrorData, IManagersHR, SearchParams } from 'interfaces/global/globalInterfaces';
import { BorderLinearProgress } from 'components/Loader/Loader';
import { StaffFilters } from 'components/MainFilters/StaffFilters';
import { Box } from '@mui/system';

import { throttle } from 'utils/tablesMethods';
import { InfoText } from 'ui/other/Typography';
import { useSnackbar } from 'notistack';
import { useGetExportPatientsToExcel } from 'hooks/companies/useGetExportPatientsToExcel';
import { useGetPeriodStaffAction } from 'hooks/periods/useGetPeriodStaffAction';
import { parse } from 'date-fns';
import { useSearchProgramsForStaffFilter } from 'hooks/programs/useSearchProgramsForStaffFilter';
import { isAccessAllowed } from 'utils/isAccessAllowed';
import { sessionStorageGetItem } from 'utils/sessionStorageGetItem';
import { defaultTableFields } from 'utils/constants';
import { useGetCompanyAdmins } from 'hooks/permissions/useGetCompanyAdmins';
import { useGetAccessList } from 'hooks/permissions/useGetAccessList';
import { useGetUserRole } from 'hooks/permissions/useGetUserRole';
import { useGetDefaultPermissions } from 'hooks/permissions/useGetDefaultPermissions';
import { analyticsTracker } from 'utils/analytics';

interface IPatientProps {
  handleManagersHR: (value: IManagersHR | null) => void;
  managersHR: IManagersHR | null;
}

export const Staff: React.FC<IPatientProps> = ({ handleManagersHR, managersHR }) => {
  // sessionStorage для главных фильтров
  const mainFilterCompaniesStaff: Company[] =
    'mainFilterCompaniesStaff' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('mainFilterCompaniesStaff') || '[]')
      : [];
  const mainFilterPeriodStaff: DataPeriod =
    'mainFilterPeriodStaff' in sessionStorage ? JSON.parse(sessionStorage.getItem('mainFilterPeriodStaff') || '') : '';

  // sessionStorage для фильтров таблицы

  const tableFilterPatientCustomSearchType: string | null =
    sessionStorage.getItem('tableFilterPatientCustomSearchType') || null;
  const tableFilterPatientCustomSearchValue: string =
    'tableFilterPatientCustomSearchValue' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('tableFilterPatientCustomSearchValue') || '')
      : '';
  const tableFilterPatientByPrograms: SearchParams[] =
    'tableFilterPatientByPrograms' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('tableFilterPatientByPrograms') || '')
      : [];
  const tableFilterPatientByStartDate: string =
    'tableFilterPatientByStartDate' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('tableFilterPatientByStartDate') || '')
      : '';
  const tableFilterPatientByEndDate: string =
    'tableFilterPatientByEndDate' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('tableFilterPatientByEndDate') || '')
      : '';
  const tableFilterPatientByType: SearchParams[] =
    'tableFilterPatientByType' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('tableFilterPatientByType') || '')
      : [];
  const tableFilterPatientRelativeType: SearchParams[] =
    'tableFilterPatientRelativeType' in sessionStorage
      ? JSON.parse(sessionStorage.getItem('tableFilterPatientRelativeType') || '')
      : [];

  const tableFields = sessionStorageGetItem('tableUserFields', defaultTableFields);

  const [isCompanyCheck, setCompanyCheck] = useState<boolean>(false);
  const [isStaffCheck, setStaffCheck] = useState<boolean>(false);
  const [selectedStaff, setSelectedStaff] = useState<string[]>([]);
  const [hasSelectedCompletedPatients, setAvailabilityCompletedPatients] = useState<boolean>(false);

  const [companiesHR, setCompaniesHR] = useState<Company[]>([]);

  const [periodHR, setPeriodInMainFilterHR] = useState<DataPeriod | null>(null);

  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const [dataPeriods, setDataPeriods] = useState<DataPeriod[]>([]);
  const [allCompanies, setCompanies] = useState<Company[]>([]);

  const [periodInMainFilter, setPeriodInMainFilter] = useState<DataPeriod | null>(null);

  // фильтры для таблицы

  const [tableUserFields, setTableUserFields] = useState<string[]>(
    tableFields.length < 1 ? defaultTableFields : tableFields
  );
  const [searchStaffCustomField, setSearchStaffCustomField] = useState<string>(
    tableFilterPatientCustomSearchValue || ''
  );

  const [searchType, setSearchType] = useState<string | null>(tableFilterPatientCustomSearchType || 'search');

  const [selectedStaffFilterPrograms, setSelectedStaffFilterPrograms] = useState<SearchParams[]>(
    tableFilterPatientByPrograms || []
  );
  const [startDate, setStartDate] = useState<Date | null>(
    tableFilterPatientByStartDate ? new Date(tableFilterPatientByStartDate) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    tableFilterPatientByEndDate ? new Date(tableFilterPatientByEndDate) : null
  );
  const [selectedTypeStaff, setSelectedTypeStaff] = useState<SearchParams[]>(tableFilterPatientByType || []);
  const [selectedPatientType, setSelectedPatientType] = useState<SearchParams[]>(tableFilterPatientRelativeType || []);

  const [orderingTableStaff, setOrderingTableStaff] = useState<string>('');

  const [isAddingForbiddenByDates, setAddingForbiddenByDates] = useState<boolean>(false);
  const [isDetachingForbiddenByDates, setDetachingForbiddenByDates] = useState<boolean>(false);

  const [isActionWithStaffTooltip, setActionWithStaffTooltip] = useState<boolean>(false);

  const [currentPatient, setPatient] = useState<PatientParams>({
    patientTitle: null,
    patientUuid: null,

    programTitle: null,
    programUuid: null,
    start_date: tableFilterPatientByStartDate || null,
    end_date: tableFilterPatientByEndDate || null,

    only_active: null,
    typePeriod: null,
    typePeriodTitle: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCompanyCheck = (value: boolean): void => setCompanyCheck(value);
  const handleStaffCheck = (value: boolean): void => setStaffCheck(value);
  const handleCompanyGet = React.useCallback(() => {
    setCompaniesHR(selectedCompanies);
  }, [selectedCompanies]);

  const token = localStorage.getItem('token');

  const { enqueueSnackbar } = useSnackbar();

  const { accessList } = useGetAccessList({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { rolesData } = useGetUserRole({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { defaultPermissions } = useGetDefaultPermissions({
    isLoggedIn: !!token,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const isSlotsAviable = mainFilterCompaniesStaff
    ?.map((item: Company) => isAccessAllowed(item?.uuid, 'HR_slots', accessList, defaultPermissions))
    ?.some((key) => !!key);

  const isReportAviable =
    mainFilterCompaniesStaff?.length === 1
      ? isAccessAllowed(mainFilterCompaniesStaff[0]?.uuid, 'HR_slots_report', accessList, defaultPermissions)
      : mainFilterCompaniesStaff?.every((item: Company) =>
          isAccessAllowed(item?.uuid, 'HR_slots_report', accessList, defaultPermissions)
        ) ?? false;

  const isSlotActionsAviable =
    mainFilterCompaniesStaff
      ?.map((item: Company) => isAccessAllowed(item?.uuid, 'HR_slots', accessList, defaultPermissions))
      ?.some((key) => !!key) &&
    mainFilterCompaniesStaff
      ?.map((item: Company) => isAccessAllowed(item?.uuid, 'HR_slots_action_attach', accessList, defaultPermissions))
      ?.some((key) => !!key);

  const { isLoadingCompanies, isErrorCompanies, fetchNextPageCompanies } = useGetCompanies({
    onSuccess: ({ pageParams, companiesSearch }) => {
      const allPage: number = pageParams?.pages[0]?.meta
        ? Math.ceil(pageParams.pages[0].meta.total / pageParams.pages[0].meta.per_page)
        : 0;

      if (selectedCompanies.length === 0 && mainFilterCompaniesStaff.length === 0) {
        setSelectedCompanies([companiesSearch[0]]);
        sessionStorage.setItem('mainFilterCompaniesStaff', JSON.stringify([companiesSearch[0]]));
      } else if (mainFilterCompaniesStaff) {
        setSelectedCompanies(
          companiesSearch.filter((ele) => mainFilterCompaniesStaff.map((item) => item.uuid).includes(ele.uuid))
        );
        sessionStorage.setItem(
          'mainFilterCompaniesStaff',
          JSON.stringify(
            companiesSearch.filter((ele) => mainFilterCompaniesStaff.map((item) => item.uuid).includes(ele.uuid))
          )
        );
      }

      if (companiesSearch) setCompanies(companiesSearch);

      if (pageParams.pages.length < allPage) fetchNextPageCompanies();
    },
  });

  const { isLoadingServicePeriods, isErrorServicePeriods } = useGetServicePeriods({
    companies: selectedCompanies,
    onSuccess: (data: DataPeriod[]) => {
      const res = data.reverse();

      setDataPeriods(res);
      const activePeriodExist = res.some((item) => item.activePeriod);
      if (selectedCompanies.length === 1) {
        res.forEach((period: DataPeriod) => {
          if (mainFilterPeriodStaff) {
            if (mainFilterPeriodStaff.uuid === period.uuid) {
              setPeriodInMainFilter(period);
              sessionStorage.setItem('mainFilterPeriodStaff', JSON.stringify(period));
              handleManagersHR({
                accountEmail: period.accountEmail,
                accountPhone: period.accountPhone,
                curatorEmail: period.curatorEmail,
                curatorPhone: period.curatorPhone,
                accountFullName: period.accountFullName,
                doctorFullName: period.doctorFullName,
                doctorEmail: period.doctorEmail,
              });
            }
          } else if (period.activePeriod) {
            setPeriodInMainFilter(period);
            sessionStorage.setItem('mainFilterPeriodStaff', JSON.stringify(period));

            handleManagersHR({
              accountEmail: period.accountEmail,
              accountPhone: period.accountPhone,
              curatorEmail: period.curatorEmail,
              curatorPhone: period.curatorPhone,
              accountFullName: period.accountFullName,
              doctorFullName: period.doctorFullName,
              doctorEmail: period.doctorEmail,
            });
          } else if (!activePeriodExist) {
            setPeriodInMainFilter(res[0]);
            sessionStorage.setItem('mainFilterPeriodStaff', JSON.stringify(res[0]));
            handleManagersHR({
              accountEmail: res[0].accountEmail,
              accountPhone: res[0].accountPhone,
              curatorEmail: res[0].curatorEmail,
              curatorPhone: res[0].curatorPhone,
              accountFullName: res[0].accountFullName,
              doctorFullName: res[0].doctorFullName,
              doctorEmail: res[0].doctorEmail,
            });
          }
        });
      } else {
        setPeriodInMainFilter(null);
        sessionStorage.setItem('mainFilterPeriodStaff', JSON.stringify(''));

        if (selectedCompanies.length > 1) {
          handleManagersHR({
            accountEmail: res[0].accountEmail,
            accountPhone: res[0].accountPhone,
            curatorEmail: res[0].curatorEmail,
            curatorPhone: res[0].curatorPhone,
            accountFullName: res[0].accountFullName,
            doctorEmail: res[0].doctorEmail,
          });
        } else {
          handleManagersHR(null);
        }
      }
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { periodStaffAction } = useGetPeriodStaffAction({
    period: periodInMainFilter,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response.json().then((result) => {
          result.errors.forEach((item: ErrorData) =>
            enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
          );
        });
      }
    },
  });

  const { programsData, isLoadingGetProgramsForFilter } = useSearchProgramsForStaffFilter({
    periods: dataPeriods,
    periodInMainFilter,
    companies: selectedCompanies,
    isSlotsAviable,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const {
    searchPatientsData,
    isLoadingSearchPatients,
    isErrorSearchPatients,
    hasNextPageSearchPatients,
    isFetchingNextPageSearchPatients,
    isFetchingSearchPatients,
    fetchNextPageSearchPatients,
    refetchSearchPatients,
  } = useSearchPatients({
    isSlotsAviable,
    userEndDate: endDate,
    userStartDate: startDate,
    companies: selectedCompanies,
    period: periodHR,
    activePeriods: dataPeriods,
    search: searchStaffCustomField,
    searchType,
    programs: selectedStaffFilterPrograms,
    patient: currentPatient,
    types: selectedTypeStaff,
    orderList: orderingTableStaff,
    patientRelativeTypes: selectedPatientType,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const { refetchExportPatientsToExcel } = useGetExportPatientsToExcel({
    userEndDate: endDate,
    userStartDate: startDate,
    companies: selectedCompanies,
    period: periodHR,
    activePeriods: dataPeriods,
    fullname: searchStaffCustomField,
    programs: selectedStaffFilterPrograms,
    patient: currentPatient,
    types: selectedTypeStaff,
    onSuccess: () => {
      enqueueSnackbar('Файл был сформирован и отправлен на Email адрес вашего аккаунта', { variant: 'success' });
      analyticsTracker.trackExportPatient();
    },
    onError: () => {
      enqueueSnackbar('Возникла ошибка при выгрузке данных!', { variant: 'error' });
    },
  });

  const { companyAdminsData } = useGetCompanyAdmins({
    companyUuid: selectedCompanies[0]?.uuid,
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка', { variant: 'error' })
            )
          );
      }
    },
  });

  const handleScroll = async (evt: Event): Promise<void> => {
    const { scrollHeight, scrollTop } = (evt.target as Document).documentElement;
    const windowHeight = window.innerHeight;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    if (scrollHeight - (windowHeight + scrollTop) < 50) {
      await fetchNextPageSearchPatients();
    }
  };

  useEffect(() => {
    if (hasNextPageSearchPatients && !isFetchingNextPageSearchPatients) {
      document.addEventListener('scroll', throttle(handleScroll, 1000));
    }

    return () => document.removeEventListener('scroll', throttle(handleScroll, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPageSearchPatients, isFetchingNextPageSearchPatients]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setPeriodInMainFilterHR(periodInMainFilter);
  }, [setPeriodInMainFilter, periodInMainFilter]);

  useEffect(() => {
    handleCompanyGet();
  }, [handleCompanyGet, allCompanies, selectedCompanies]);

  useEffect(() => {
    handleCompanyCheck(selectedCompanies.length > 0);
  }, [handleCompanyCheck, selectedCompanies.length]);

  useEffect(() => {
    const parseCanAttachSince = periodStaffAction
      ? parse(periodStaffAction.canAttachSince, 'yyyy-MM-dd', new Date()).getTime()
      : null;

    const parseCanDetachSince = periodStaffAction
      ? parse(periodStaffAction.canDetachSince, 'yyyy-MM-dd', new Date()).getTime()
      : null;

    const parseCanActionMax = periodStaffAction
      ? parse(periodStaffAction.canAttachOrDetachMaxDate, 'yyyy-MM-dd', new Date()).getTime()
      : null;

    if (parseCanAttachSince && parseCanActionMax && parseCanDetachSince) {
      setAddingForbiddenByDates(parseCanActionMax - parseCanAttachSince < 0);
      setDetachingForbiddenByDates(parseCanActionMax - parseCanDetachSince < 0);
    }
  }, [periodInMainFilter, periodStaffAction]);

  if (isErrorCompanies || isErrorServicePeriods || isErrorSearchPatients) {
    const textError: string[] = [];

    if (isErrorCompanies) textError.push('по компаниям');
    if (isErrorServicePeriods) textError.push('по сервисным периодам');
    if (isErrorSearchPatients) textError.push('по пациентам');

    return (
      <InfoText variant="h3">
        {`Что-то пошло не так. Не удалось получить данные ${textError.join(',')}. Попробуйте позже`}
      </InfoText>
    );
  }

  return (
    <>
      {((isLoadingCompanies && !!selectedCompanies.length) ||
        (isLoadingServicePeriods && !!selectedCompanies.length)) && <BorderLinearProgress />}

      <StaffNavigation
        isAddingForbiddenByDates={isAddingForbiddenByDates}
        isDetachingForbiddenByDates={isDetachingForbiddenByDates}
        isCompanyCheck={isCompanyCheck}
        isStaffCheck={isStaffCheck}
        selectedStaff={selectedStaff}
        periodHR={periodHR}
        companiesHR={companiesHR}
        hasSelectedCompletedPatients={hasSelectedCompletedPatients}
        selectedCompanies={selectedCompanies}
        periodInMainFilter={periodInMainFilter}
        isPeriodActive={periodHR ? periodHR.activePeriod : false}
        handleExportToExcel={refetchExportPatientsToExcel}
        isActionWithStaffTooltip={isActionWithStaffTooltip}
        handleActionWithStaffTooltip={setActionWithStaffTooltip}
        isReportAviable={isReportAviable}
        isSlotActionsAviable={isSlotActionsAviable}
        accessList={accessList}
        rolesData={rolesData}
        defaultPermissions={defaultPermissions}
      />

      <StaffFilters
        managersHR={managersHR}
        dataPeriods={dataPeriods || []}
        allCompanies={allCompanies}
        periodInMainFilter={periodInMainFilter}
        handlePeriodInMainFilter={setPeriodInMainFilter}
        selectedCompanies={selectedCompanies}
        handleSelectedCompanies={setSelectedCompanies}
        periodStaffAction={periodStaffAction}
        isSlotsAviable={isSlotsAviable}
      />

      {isSlotsAviable ? (
        <>
          <AccordionHistory selectedCompanies={selectedCompanies} isSlotsAviable={isSlotsAviable} />

          <Box component="section" sx={{ marginTop: '32px' }}>
            {!!selectedCompanies.length && (
              <ListStaff
                isAddingForbiddenByDates={isAddingForbiddenByDates}
                isDetachingForbiddenByDates={isDetachingForbiddenByDates}
                selectedCompanies={selectedCompanies}
                staffQuantity={searchPatientsData?.pageParams.total || 0}
                isPatients={searchPatientsData?.patients || []}
                currentPatient={currentPatient}
                setPatient={setPatient}
                orderingTableStaff={orderingTableStaff}
                searchStaffCustomField={searchStaffCustomField}
                tableUserFields={tableUserFields}
                handleTableFields={setTableUserFields}
                handleSearchType={setSearchType}
                searchType={searchType}
                startDate={startDate}
                handleStartDate={setStartDate}
                endDate={endDate}
                handleEndDate={setEndDate}
                selectedTypeStaff={selectedTypeStaff}
                handleSelectedTypeStaff={setSelectedTypeStaff}
                selectedPatientType={selectedPatientType}
                handleSelectedPatientType={setSelectedPatientType}
                programFilterOptions={programsData}
                handleSearchStaffCustomField={setSearchStaffCustomField}
                selectedStaffFilterPrograms={selectedStaffFilterPrograms}
                setSelectedStaffFilterPrograms={setSelectedStaffFilterPrograms}
                isLoadingServicePeriods={isLoadingServicePeriods}
                isLoadingSearchPatients={isLoadingSearchPatients}
                isFetchingSearchPatients={isFetchingSearchPatients}
                isLoadingDataFiltersPatients={isLoadingGetProgramsForFilter}
                isFetchingNextPageSearchPatients={isFetchingNextPageSearchPatients}
                handleStaffCheck={handleStaffCheck}
                setSelectedStaff={setSelectedStaff}
                handleAvailabilityCompletedPatients={setAvailabilityCompletedPatients}
                handleOrderingTableStaff={setOrderingTableStaff}
                handleActionWithStaffTooltip={setActionWithStaffTooltip}
                accessList={accessList}
                rolesData={rolesData}
                defaultPermissions={defaultPermissions}
                isSlotsAviable={isSlotsAviable}
                refetchSearchPatients={refetchSearchPatients}
              />
            )}
          </Box>
        </>
      ) : (
        <>
          {selectedCompanies.length ? (
            <InfoText variant="h5">
              {' '}
              Для просмотра данного раздела у вас недостаточно прав, обратитесь к администратору{' '}
              {selectedCompanies.length === 1 &&
                companyAdminsData
                  ?.slice(0, 3)
                  ?.map((item) => item?.user?.email)
                  ?.join(',')}
              , если вам необходимы данные для работы
            </InfoText>
          ) : (
            <InfoText variant="h5">Для отображения данных выберите компанию в фильтре Компания</InfoText>
          )}
        </>
      )}
    </>
  );
};
