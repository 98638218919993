import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Button, IconButton, Stack, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SignInButton } from 'shared/buttons/SignInButton/SignInButton';
import { useAuth } from 'hooks/auth/useAuth';
import { ErrorData, IFormLogin, IValuesAuth } from 'interfaces/global/globalInterfaces';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { loginInputStyle } from 'ui/login/login';
import { useSnackbar } from 'notistack';
import { SuccessDataAuth } from 'interfaces/hooks/globalHooksInterfaces';
import { validationLoginData, LoginData } from './validationLogin';

export const FormLogin: React.FC<IFormLogin> = ({
  isLoadingSession,
  isErrorSession,
  handleModalPass,
  handleLoggedIn,
}) => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { isLoadingAuth, handleUseAuth } = useAuth({
    onSuccess: (res: SuccessDataAuth) => {
      const { data } = res;
      const token: string = data.access;
      const tokenRefresh: string = data.refresh;

      localStorage.removeItem('keySession');

      const dateToday = new Date();
      const parseEndToken = dateToday.setMinutes(dateToday.getUTCMinutes() + 59);

      localStorage.setItem('token', token);
      localStorage.setItem('refresh', tokenRefresh);

      localStorage.setItem('tokenExpirationDate', JSON.stringify(parseEndToken));

      handleLoggedIn(true);

      navigate('/staff');
    },
    onError: (res) => {
      if (res.response.status >= 400 && res.response.status < 500) {
        res.response
          .json()
          .then((result) =>
            result.errors.forEach((item: ErrorData) =>
              enqueueSnackbar(item.message || 'Произошла ошибка при авторизации', { variant: 'error' })
            )
          );
      }
    },
  });

  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = (): void => setShowPassword(!isShowPassword);
  const handleMouseDownPassword = (): void => setShowPassword(!isShowPassword);

  const handleClickSignInButton = (valuesAuth: IValuesAuth): void => {
    handleUseAuth(valuesAuth);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: zodResolver(validationLoginData),
  });

  const onSubmit: SubmitHandler<LoginData> = (values): void => {
    handleClickSignInButton(values);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="column"
        sx={{
          marginBottom: '32px',
        }}
      >
        <TextField
          sx={loginInputStyle}
          error={!!errors.email?.message}
          label="Ваш e-mail"
          variant="standard"
          helperText={errors.email?.message}
          onInvalid={(e) => e.preventDefault()}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('email')}
        />
        <TextField
          sx={loginInputStyle}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {isShowPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={errors.password?.message}
          label="Ваш пароль"
          error={!!errors.password?.message}
          type={isShowPassword ? 'text' : 'password'}
          variant="standard"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('password')}
        />
      </Stack>

      <Stack flexDirection="row" gap="16px">
        <SignInButton textButton="Войти" loading={isLoadingAuth} disabled={isLoadingSession || !!isErrorSession} />
        <Button onClick={handleModalPass} sx={{ width: '272px' }}>
          Забыли пароль?
        </Button>
      </Stack>
    </Box>
  );
};
